import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomPaginatorIntl extends MatPaginatorIntl {
  constructor(private translocoService: TranslocoService) {
    super();
    this.initTranslations();
    this.translocoService.langChanges$.subscribe(() => {
      this.initTranslations();
      this.changes.next(); // Notify Angular Material that labels have changed
    });
  }

  private initTranslations() {
    this.translocoService
      .selectTranslate('paginator.itemsPerPageLabel')
      .subscribe((translation) => {
        this.itemsPerPageLabel = translation;
      });
    this.translocoService
      .selectTranslate('paginator.nextPageLabel')
      .subscribe((translation) => {
        this.nextPageLabel = translation;
      });
    this.translocoService
      .selectTranslate('paginator.previousPageLabel')
      .subscribe((translation) => {
        this.previousPageLabel = translation;
      });
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return this.translocoService.translate('paginator.rangeLabel', {
          currentPage: 0,
          totalPages: 0,
          totalItems: length,
        });
      }

      const totalPages = Math.ceil(length / pageSize);
      const currentPage = page + 1;

      return this.translocoService.translate('paginator.rangeLabel', {
        currentPage,
        totalPages,
        totalItems: length,
      });
    };
  }
}
