import { Route } from '@angular/router';
import { AuthGuard, LayoutComponent, NoAuthGuard } from '@bmat-starter/core';

import { InitialDataResolver } from './app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [


  { path: '', pathMatch: 'full', redirectTo: 'landing' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'auth'
    },
    children: [
      { path: 'forgot-password', loadChildren: () => import('./modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
      { path: 'reset-password', loadChildren: () => import('./modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
      { path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
    ]
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'sign-out', loadChildren: () => import('./modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
      { path: 'unlock-session', loadChildren: () => import('./modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) }
    ],



  },


  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [

      // Dashboards
      {
        path: 'dashboards', children: [
          { path: 'project', loadChildren: () => import('./modules/admin/dashboards/project/project.module').then(m => m.ProjectModule) },
        ]
      },
      { path: 'top-10', loadChildren: () => import('./modules/pages/landing/landing.module').then(m => m.LandingModule) },
      { path: 'development', loadChildren: () => import('./modules/pages/development/development.module').then(m => m.DevelopmentModule) },
// Settings
      {path: 'landing', loadChildren: () => import('./modules/pages/settings/settings.module').then(m => m.SettingsModule)},

      // Advanced Search
      { path: 'advanced-search', loadChildren: () => import('./modules/pages/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule) },


      // Page Layouts
      { path: 'page-layouts', loadChildren: () => import('./modules/admin/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule) },

      { path: 'typography', loadChildren: () => import('./modules/ui/typography/typography.module').then(m => m.TypographyModule) },
      // TailwindCSS
      { path: 'tailwindcss', loadChildren: () => import('./modules/ui/tailwindcss/tailwindcss.module').then(m => m.TailwindCSSModule) },


      // Colors
      { path: 'colors', loadChildren: () => import('./modules/ui/colors/colors.module').then(m => m.ColorsModule) },
        // Colors
        { path: 'buttons', loadChildren: () => import('./modules/ui/buttons/buttons.module').then(m => m.ButtonsModule) },
      // Confirmation Dialog
      { path: 'confirmation-dialog', loadChildren: () => import('./modules/ui/confirmation-dialog/confirmation-dialog.module').then(m => m.ConfirmationDialogModule) },

        // Forms
        {path: 'forms', children: [
          {path: 'fields', loadChildren: () => import('./modules/ui/forms/fields/fields.module').then(m => m.FormsFieldsModule)},
          {path: 'layouts', loadChildren: () => import('./modules/ui/forms/layouts/layouts.module').then(m => m.FormsLayoutsModule)},
          {path: 'wizards', loadChildren: () => import('./modules/ui/forms/wizards/wizards.module').then(m => m.FormsWizardsModule)}
      ]},

       // Datatable
       {path: 'datatable', loadChildren: () => import('./modules/ui/datatable/datatable.module').then(m => m.DatatableModule)},


         // Animations
         {path: 'animations', loadChildren: () => import('./modules/ui/animations/animations.module').then(m => m.AnimationsModule)},
         // Animations
         {path: 'tools', loadChildren: () => import('./modules/ui/tools/tools.module').then(m => m.ToolsModule)},

    ]
  },
  // Maintenance
  { path: 'maintenance', loadChildren: () => import('./modules/pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },

  // 404 & Catch all
  { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('./modules/pages/error/error-404/error-404.module').then(m => m.Error404Module) },
  { path: '**', redirectTo: '404-not-found' }
];
