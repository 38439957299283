import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '@bmat-starter/shared';
import { MembersComponent } from './members.component';
import { MatSelectModule } from '@angular/material/select';
import { MemberService } from './member.service';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [MembersComponent],
  providers: [MemberService, { provide: TRANSLOCO_SCOPE, useValue: 'ui' }],
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    SharedModule,
    TranslocoModule,
  ],
  exports: [MembersComponent],
})
export class MembersModule {}
