<!-- Loading bar -->
<ui-loading-bar></ui-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <vertical-navigation
    class="dark bg-gray-900 print:hidden "
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
  >
    <!-- Navigation header hook -->
    <ng-container uiVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-6 px-8">
        <img
          class="w-24"
          src="assets/logo/logo-scpp-white.png"
          alt="Logo image"
        />
      </div>
    </ng-container>
  </vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="fixed top-0 left-0 flex flex-0
    items-center bg-gradient-to-r
    from-primary-500 from-97%  to-accent-500 to-3% text-white  w-full h-16 sm:h-20 px-4 md:px-6 z-49
    shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    </ng-container>
    <ng-container >
      <!-- Logo -->
      <div class="flex items-center  m-auto md:mx-2 lg:mr-8">
        <div class="flex flex-row items-center  dark:text-green-300 text-lg md:ml-2">
          <img
            class=" dark:flex  w-26"
            src="assets/logo/logo-scpp-white.png"
          />
            <img class=" w-36 pl-3" src="assets/logo/logo-bmat-white.png" />
        </div>
      </div>
      <!-- Horizontal navigation -->
    </ng-container>

    <!-- Components -->
    <div class="flex items-center pl-2 md:ml-auto space-x-0.5 sm:space-x-2">

      <horizontal-navigation
      *ngIf="!isScreenSmall"
      class="mr-2 sticky"
      [name]="'mainNavigation'"
      [navigation]="navigation.horizontal"
    ></horizontal-navigation>

      <members class="mr-3"></members>
      <user-horizontal></user-horizontal>
      <languages></languages>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full md:mt-18">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div
    class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary"
      >Nous utilisons la donnée de durée totale d’utilisation de chaque titre
      identifiée sur la période observée grâce aux technologies d’empreinte,
      nous les rapprochons des informations membres, et les multiplions par les
      estimations de la valeur de la seconde de diffusion provenant de la
      SCPP.</span
    >
  </div> -->
  <footer></footer>
</div>
