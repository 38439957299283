import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

import { SettingsComponent } from './settings.component';
import { MatButtonModule } from '@angular/material/button';
import { DrawerModule } from '@bmat-starter/ui';

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    DrawerModule,
    MatButtonModule,
  ],
  exports: [SettingsComponent],
})
export class SettingsModule {}
