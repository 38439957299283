import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@bmat-starter/shared';
import { LoadingBarModule } from '@bmat-starter/ui';
import { AuthLayoutComponent } from './auth.component';


@NgModule({
    declarations: [
        AuthLayoutComponent
    ],
    imports     : [
        RouterModule,
        LoadingBarModule,
        SharedModule,
        LoadingBarModule,
    ],
    exports     : [
        AuthLayoutComponent
    ]
})
export class AuthLayoutModule
{
}
