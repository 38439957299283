import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { ModernLayoutComponent } from './modern.component';
import { SharedModule } from '@bmat-starter/shared';
import { UserHorizontalModule } from '../../../common/uesr-horizontal/user-horizontal.module';
import { FullscreenModule, LoadingBarModule, NavigationModule } from '@bmat-starter/ui';
import { NotificationsModule } from '../../../common/notifications';
import { MessagesModule } from '../../../common/messages';
import { FooterModule } from '../../../common/footer/footer.module';
import { MembersModule } from '../../../common/members/members.module';
import { LanguagesModule } from '../../../common/languages/languages.module';

@NgModule({
    declarations: [
        ModernLayoutComponent
    ],
    imports     : [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FullscreenModule,
        LoadingBarModule,
        NavigationModule,
        MessagesModule,
        FooterModule,
        NotificationsModule,
        UserHorizontalModule,
        MembersModule,
        SharedModule,
        LanguagesModule
    ],
    exports     : [
        ModernLayoutComponent
    ]
})
export class ModernLayoutModule
{
}
