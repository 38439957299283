import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { MessagesService, NotificationsService, UserService } from '@bmat-starter/core';
import { NavigationService } from 'libs/core/src/lib/core/navigation/navigation.service';
// import { NavigationService } from 'libs/core/src/lib/core/navigation/navigation.service';


@Injectable({
  providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _messagesService: MessagesService,
    private _notificationsService: NotificationsService,
    private _userService: UserService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
      this._navigationService.get(),
      this._messagesService.getAll(),
      this._notificationsService.getAll(),
     // this._userService.get()
    ]);
  }
}
