import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';




@Component({
    selector       : 'footer',
    templateUrl    : './footer.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'footer'
})
export class FooterComponent  {

}
