// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://creme-brulee-api-development.broadcast.bmat.com/api/',
  // API_URL: process.env['BMAT_DEV_API_URL'],
  START_DATE: '01 jan 2021',
  END_DATE: '31 Dec 2021',
  CURRENT_YEAR: 2023,
  PREVIOUS_YEAR: 2022,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
