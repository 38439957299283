<div
  class="relative md:flex px-4 pt-6 md:pt-10 bg-primary-500 dark:bg-transparent print:hidden text-white text-opacity-80"
>
  <!--  Contact Scpp -->
  <div class="flex items-start mt-4 mx-auto">
    <div class="flex flex-col items-start text-lg md:ml-2">
      <img class="dark:flex w-44" src="assets/logo/logo-scpp-white.png" />
      <img class="w-48 pt-3" src="assets/logo/logo-bmat-white.png" />
    </div>
  </div>

  <!--  Contact BMAT -->
  <div class="flex items-start mt-4 mx-auto sm:w-full md:w-auto">
    <div
      class="flex flex-col items-start text-lg ml-2 font-light sm:w-full md:w-auto mr-3 md:mr-0"
    >
      <div
        class="border-t sm:w-full border-white p-2 md:font-semibold md:border-none md:p-0"
      >
        {{ 'ui.contact' | transloco }}
      </div>
      <div class="border-t border-white sm:w-full p-2 md:border-none md:p-0">
        14 bd. du Général Leclerc
      </div>
      <div class="border-t border-white sm:w-full p-2 md:border-none md:p-0">
        92527 Neuilly-Sur-Seine Cedex
      </div>
      <div
        class="md:mt-7 sm:hidden md:block border-t border-white sm:w-full p-1 md:border-none md:p-0"
      >
        <mat-icon
          class="text-white text-xs h-3 w-3 min-h-3 min-w-3"
          svgIcon="heroicons_solid:phone"
        ></mat-icon>
        +33 (0)1 41 43 03 03
      </div>
      <div
        class="sm:hidden md:block border-t border-white sm:w-full p-1 md:border-none md:p-0"
      >
        <mat-icon
          class="text-white text-xs h-3 w-3 min-h-3 min-w-3"
          svgIcon="heroicons_solid:phone"
        ></mat-icon>
        +33 (0)1 41 43 03 26
      </div>
      <div
        class="sm:hidden md:block border-t border-white sm:w-full p-1 md:border-none md:p-0"
      >
        <mat-icon
          class="text-white text-xs h-3 w-3 min-h-3 min-w-3"
          svgIcon="mat_solid:email"
        ></mat-icon>
        contact@scpp.fr
      </div>
    </div>
  </div>

  <!--  Contact BMAT -->
  <div class="flex items-start md:mt-4 mx-auto sm:w-full md:w-auto">
    <div
      class="flex flex-col items-start text-lg ml-2 font-light sm:w-full md:w-auto mr-3 md:mr-0"
    >
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        {{ 'ui.useful' | transloco }}
      </div>
      <div class="sm:hidden md:block">Presse</div>
      <div class="sm:hidden md:block">Questions fréquentes</div>
      <div class="sm:hidden md:block">Adresses utiles</div>
      <div class="sm:hidden md:block">Téléchargements</div>
      <div class="sm:hidden md:block">Législation</div>
      <div class="sm:hidden md:block">Lexique</div>
    </div>
  </div>

  <!--  Contact BMAT -->
  <div class="flex items-start md:mt-4 mx-auto sm:w-full md:w-auto">
    <div
      class="flex flex-col items-start text-lg ml-2 font-light sm:w-full md:w-auto mr-3 md:mr-0"
    >
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        SCPP
      </div>
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        Presse
      </div>
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        MEMBRES
      </div>
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        RÉPERTOIRE
      </div>
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        ADHÉSION
      </div>
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        AIDES
      </div>
      <div
        class="border-t border-white sm:w-full p-2 md:font-semibold md:border-none md:p-0"
      >
        ISRC
      </div>
    </div>
  </div>
</div>

<div
  class="relative md:flex flex-col md:flex-row px-4 justify-items-stretch md:justify-between bg-primary-500 border-t border-white border-opacity-10 print:hidden text-white text-opacity-50"
>
  <div class="items-center justify-self-center p-2 ml-2">
    © 2018 Société Civile des Producteurs Phonographiques - Tous droits réservés
    | CGU | Mentions légales | Politique de confidentialité
  </div>
  <div class="items-center justify-self-center p-2">
    Suivez-nous :
    <mat-icon
      class="text-white text-xs h-3 w-3 min-h-3 min-w-3 ml-2 text-opacity-50"
      svgIcon="feather:twitter"
    ></mat-icon>

    <mat-icon
      class="text-white text-xs h-3 w-3 min-h-3 min-w-3 ml-2 text-opacity-50"
      svgIcon="mat_solid:facebook"
    ></mat-icon>
    <mat-icon
      class="text-white text-xs h-3 w-3 min-h-3 min-w-3 ml-2 text-opacity-50"
      svgIcon="feather:linkedin"
    ></mat-icon>
    <mat-icon
      class="text-white text-xs h-3 w-3 min-h-3 min-w-3 ml-2 text-opacity-50"
      svgIcon="feather:instagram"
    ></mat-icon>
  </div>
</div>
