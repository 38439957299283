import { provideTransloco, TranslocoModule } from '@ngneat/transloco';
import { Injectable, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-http.loader';
import { environment } from '../environments/environment';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'fr'],
        defaultLang: 'fr',
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          useFallbackTranslation: true,
        },
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR',
    },
  ],
})
export class TranslocoRootModule {}
