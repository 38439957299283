import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MutatingDotsComponent } from './mutating-dots.component';


@NgModule({
    declarations: [
        MutatingDotsComponent
    ],
    imports     : [
        CommonModule,
    ],
    exports     : [
        MutatingDotsComponent
    ]
})
export class MutatingDotsModule
{
}
