import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialLayoutComponent } from './material.component';
import { FullscreenModule, LoadingBarModule, NavigationModule } from '@bmat-starter/ui';
import { MessagesModule } from '../../../common/messages';
import { NotificationsModule } from '../../../common/notifications';
import { UserModule } from '../../../common/user/user.module';
import { SharedModule } from '@bmat-starter/shared';

@NgModule({
    declarations: [
        MaterialLayoutComponent
    ],
    imports     : [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        FullscreenModule,
        LoadingBarModule,
        NavigationModule,
        MessagesModule,
        NotificationsModule,
        UserModule,
        SharedModule
    ],
    exports     : [
        MaterialLayoutComponent
    ]
})
export class MaterialLayoutModule
{
}
