<!-- Loading bar -->
<ui-loading-bar></ui-loading-bar>



<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative  flex-0 items-center  hidden md:block   w-full h-24  px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
  <div class="  max-w-screen-lg  m-auto">
        <!-- Navigation toggle button -->
        <ng-container >
          <!-- Logo -->
          <div class="flex items-center  mx-auto mt-2 md:mx-2 lg:mr-8">
            <div class="flex flex-row items-center  dark:text-green-300 text-lg md:ml-2">
              <img
                class="hidden dark:flex  w-18"
                src="assets/logo/logo-scpp-white.png"
              />

              <img
              class="w-24 dark:hidden"
              src="assets/logo/logo-scpp.png"
              alt="Logo image"
            />
                <img class="hidden dark:flex w-26 pl-3" src="assets/logo/logo-bmat-white.png" />
                <img class="dark:hidden w-26 pl-3" src="assets/logo/logo-bmat.png" />
            </div>
          </div>
          <!-- Horizontal navigation -->
        </ng-container>

        <!-- Components -->
        <div class="flex items-center pl-2 md:ml-auto space-x-0.5 sm:space-x-2">
        </div>
  </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col max-w-screen-lg mt-8  mx-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div
    class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary"
      >Nous utilisons la donnée de durée totale d’utilisation de chaque titre
      identifiée sur la période observée grâce aux technologies d’empreinte,
      nous les rapprochons des informations membres, et les multiplions par les
      estimations de la valeur de la seconde de diffusion provenant de la
      SCPP.</span
    >
  </div> -->

</div>
