import { registerLocaleData } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';

import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CoreModule, appConfig } from '@bmat-starter/core';
import { MockApiModule, mockApiServices } from '@bmat-starter/mock-api';
import { CustomPaginatorIntl } from '@bmat-starter/shared';
import { ConfigModule, UIModule } from '@bmat-starter/ui';
import { TranslocoRootModule } from './transloco-root.module';

registerLocaleData(localeFr);
registerLocaleData(localeEn);

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  // scrollPositionRestoration: 'enabled',
  // anchorScrolling: 'enabled',
  // scrollOffset: [0, 64]
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 50],
  relativeLinkResolution: 'legacy',
};

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    RouterModule.forRoot(appRoutes, routerConfig),
    UIModule,
    ConfigModule.forRoot(appConfig),
    MatButtonModule,
    MatSliderModule,
    MockApiModule.forRoot(mockApiServices),
    TranslocoRootModule,
  ],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    // {
    //   provide: MAT_DATE_FORMATS,
    //   useValue: MY_DATE_FORMATS,
    // },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
