<!-- Loading bar -->
<ui-loading-bar></ui-loading-bar>

<!-- Navigation -->
<vertical-navigation
  *ngIf="navigation"
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container uiVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center h-15 p-6 pb-0">
      <!-- Light version -->
      <img
        class="dark:hidden w-30"
        src="assets/logo/logo-scpp.png"
        alt="Logo image"
      />
      <!-- Dark version -->
      <img
        class="hidden dark:flex w-30"
        src="assets/logo/logo-scpp-white.png"
        alt="Logo image"
      />
      <div class="flex items-center h-5 pb-0">
        <img
        class="dark:hidden w-30"
        src="assets/logo/logo-bmat-horizontal.png"
        alt="Logo image"
      />
      </div>
      <img
      class="hidden dark:flex w-30"
      src="assets/logo/logo-bmat-white.png"
      alt="Logo image"
    />
    </div>
  </ng-container>
  <!-- Navigation footer hook -->
  <ng-container uiVerticalNavigationContentFooter>
    <div
      class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12"
    >
      <img class="max-w-36" src="assets/logo/logo-scpp-white.png" />
    </div>
  </ng-container>
</vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <fullscreen class="hidden md:block"></fullscreen>

      <messages></messages>
      <notifications></notifications>

      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="relative flex flex-0 items-center justify-start w-full md:h-14 sm:text-sm sm:py-6 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary"
      >Nous utilisons la donnée de durée totale d’utilisation de chaque titre
      identifiée sur la période observée grâce aux technologies d’empreinte,
      nous les rapprochons des informations membres, et les multiplions par les
      estimations de la valeur de la seconde de diffusion provenant de la
      SCPP.</span
    >
  </div>
</div>
