import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, ReplaySubject, tap } from 'rxjs';
import { User } from './user.types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/bmat-starter/src/environments/environment';

const BASE_URL = environment.API_URL;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _member: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
    const member = localStorage.getItem('member');
    if (member) {
      this.setMember(JSON.parse(member));
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  setMember(value: any) {
    this._member.next(value);
    if (value && value != 'all') {
      localStorage.setItem('member', JSON.stringify(value));
    } else {
      localStorage.removeItem('member');
    }
  }

  getMember(): Observable<any> {
    return this._member.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  getMembers(): Observable<any> {
    return this._httpClient.get(`${BASE_URL}royalties/filter/members`);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  // get(): Observable<User>
  // {
  //     return this._httpClient.get<User>(`${BASE_URL}users/me`).pipe(
  //         tap((user) => {
  //             this._user.next(user);
  //         })
  //     );
  // }

  // /**
  //  * Update the user
  //  *
  //  * @param user
  //  */
  // update(user: User): Observable<any>
  // {
  //     return this._httpClient.patch<User>('api/common/user', {user}).pipe(
  //         map((response) => {
  //             this._user.next(response);
  //         })
  //     );
  // }
}
