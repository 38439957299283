import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedModule } from '@bmat-starter/shared';
import { UserHorizontalComponent } from './user-horizontal.component';


@NgModule({
  declarations: [
    UserHorizontalComponent
  ],
  imports: [
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    SharedModule
  ],
  exports: [
    UserHorizontalComponent
  ]
})
export class UserHorizontalModule {
}
