import { ChangeDetectionStrategy, Component,  OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { User, UserService } from '../../../user';
import { MatMenuTrigger } from '@angular/material/menu';



@Component({
  selector: 'members',
  templateUrl: './members.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user'
})
export class MembersComponent implements OnInit, OnDestroy {

  membersList = new BehaviorSubject<any[]>([]);
  singleLabel=false;
  selectedMember:any='all';

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;



  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(


    private _userService: UserService
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this.membersList =this._userService.getMembers();
    this._userService.getMembers().subscribe((value:any)=>{
      if(value && value.length==1){
        this.singleLabel=true;
        this._userService.setMember(value[0]);
      }
      this.membersList.next(value);
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onSelection(option:any){
    this.selectedMember=option;
    this._userService.setMember(option)
  }

  closeMenu(event:any){
    if(this.trigger)
    this.trigger.closeMenu();
  }

}
