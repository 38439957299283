import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  catchError,
  mergeMap,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { UserService } from '../user/user.service';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/bmat-starter/src/environments/environment';

const BASE_URL = environment.API_URL;

@Injectable()
export class AuthService {
  private _authenticated = false;

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ?? '';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { username: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    const formData: any = new FormData();
    formData.append('username', credentials.username);
    formData.append('password', credentials.password);
    return this._httpClient.post(`${BASE_URL}users/login`, formData).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.access_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        // this._userService.user = response.user;
        // console.log(" token deconded ..",this.accessToken)
        // Return a new observable with the response
        return of(response);
      }),
      switchMap((_) => this.signInUsingToken())
    );
  }
  /**
   * Sign in using client email and token
   *
   * @param email
   * @param token
   */
  signInExternal(credentials: {
    email: string;
    token: string;
  }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }
    return this._httpClient
      .post(`${BASE_URL}users/signin`, credentials, {
        headers: { 'Content-Type': 'application/json' },
      })
      .pipe(
        switchMap((response: any) => {
          // Check if the response contains the token
          if (response && response.access_token) {
            // Store the access token in the local storage
            this.accessToken = response.access_token;
            // Set the authenticated flag to true
            this._authenticated = true;
            return of(response);
          } else {
            // Handle missing token case
            return throwError(() => new Error('No access token in response'));
          }
        }),
        mergeMap(() => this.signInUsingToken()) // Use mergeMap instead of switchMap if you want both to complete
      );
  }

  /**
   * Sign in using the access token
   */
  signInUsingToken(): Observable<any> {
    // Renew token
    return this._httpClient.get(`${BASE_URL}users/me`).pipe(
      catchError(() =>
        // Return false
        of(false)
      ),
      switchMap((response: any) => {
        // Store the access token in the local storage
        //  this.accessToken = response.access_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service

        this._userService.user = response;

        // Return true
        return of(true);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('member');
    this._userService.setMember(null);
    //localStorage.clear();
    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: {
    email: string;
    password: string;
  }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    // if ( AuthUtils.isTokenExpired(this.accessToken) )
    // {
    //     return of(false);
    // }

    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }
}
