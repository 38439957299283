import { AuthMockApi } from './common/auth/api';
import { UserMockApi } from './common/user/api';
import { LandingMockApi } from './dashboards/landing/api';
import { IconsMockApi } from './ui/icons/api';
import { MessagesMockApi } from './common/messages/api'
import { NotificationsMockApi } from './common/notifications/api'
import { NavigationMockApi } from './common/navigation/api'
import { ProjectMockApi } from './dashboards/project/api';


export const mockApiServices = [
  IconsMockApi,
  UserMockApi,
  AuthMockApi,
  LandingMockApi,
  MessagesMockApi,
  NotificationsMockApi,
  NavigationMockApi,
  ProjectMockApi,
];
