import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from 'apps/bmat-starter/src/environments/environment';

const BASE_URL = environment.API_URL;

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  private _member: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  setMember(value: any) {
    // Store the value
    this._member.next(value);
  }

  getMember(): Observable<any> {
    return this._member.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  getMembers(): Observable<any> {
    return this._httpClient.get(`${BASE_URL}royalties/filter/members`);
  }
}
