import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ClassicLayoutComponent } from './classic.component';
import { SharedModule } from '@bmat-starter/shared';
import { FullscreenModule, LoadingBarModule, NavigationModule } from '@bmat-starter/ui';
import { MessagesModule } from '../../../common/messages';
import { NotificationsModule } from '../../../common/notifications';
import { UserModule } from '../../../common/user/user.module';

@NgModule({
  declarations: [
    ClassicLayoutComponent
  ],
  imports: [
    HttpClientModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    FullscreenModule,
    LoadingBarModule,
    NavigationModule,
    MessagesModule,
    NotificationsModule,
    UserModule,
    SharedModule
  ],
  exports: [
    ClassicLayoutComponent
  ]
})
export class ClassicLayoutModule {
}
