/* eslint-disable */
import * as moment from 'moment';

export const project = {
  artistData: {
    columns: ['id', 'title', 'label', 'passages', 'duration', 'revenueEstimated'],
    rows: [
      {
        id: 1,
        growth: 10,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
      {
        id: 2,
        growth: -3,
        title: 'Evidemment',
        artists: 'par Kendji Girac',
        label: 'Sony Music Entertainment',
        passages: 1480,
        duration: 16000,
        revenueEstimated: 1000,
      },
      {
        id: 3,
        growth: 0,
        title: 'Le Reste',
        artists: 'par Clara Luciani',
        label: 'Sony Music Entertainment',
        passages: 37480,
        duration: 3400,
        revenueEstimated: 10000,
      },
      {
        id: 4,
        growth: 10,
        title: 'De L’Or',
        artists: 'par Vitaa, Slimane',
        label: 'Universal Music Division Island ',
        passages: 75680,
        duration: 154000,
        revenueEstimated: 94450,
      },
      {
        id: 5,
        growth: 7,
        title: 'Iko Iko',
        artists: 'par Justin Wellington Feat. Small Jam',
        label: 'SignaDeccature',
        passages: 188880,
        duration: 1960,
        revenueEstimated: 74670,
      },
      {
        id: 6,
        growth: 7,
        title: 'Derrière Le Brouillard',
        artists: 'par Grand Corps Malade',
        label: 'Anouche Production',
        passages: 18880,
        duration: 1956560,
        revenueEstimated: 746470,
      },
      {
        id: 7,
        growth: 7,
        title: 'Save Your Tears',
        artists: 'par Weekend',
        label: 'Universal Music Distribution',
        passages: 13480,
        duration: 66560,
        revenueEstimated: 4470,
      },
      {
        id: 8,
        growth: 7,
        title: 'Pour De Vrai',
        artists: 'par Vianney',
        label: 'Tôt Ou Tard',
        passages: 33480,
        duration: 11560,
        revenueEstimated: 22470,
      },
      {
        id: 9,
        growth: 7,
        title: 'Un Homme',
        artists: 'par Jérémy Frérot',
        label: '6&7',
        passages: 33480,
        duration: 11560,
        revenueEstimated: 22470,
      },
      {
        id: 9,
        growth: 7,
        title: 'Maintenant',
        artists: 'par Les Enfoires',
        label: 'Sony Music/Restos Du Coeur',
        passages: 3230,
        duration: 1156230,
        revenueEstimated: 1470,
      }

    ]
  },
  artistData1: {
    columns: ['id', 'title', 'label', 'passages', 'duration', 'revenueEstimated'],
    rows: [
      {
        id: 1,
        growth: 10,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
      {
        id: 2,
        growth: -3,
        title: 'Evidemment',
        artists: 'par Kendji Girac',
        label: 'Sony Music Entertainment',
        passages: 1480,
        duration: 16000,
        revenueEstimated: 1000,
      },
      {
        id: 3,
        growth: 0,
        title: 'Le Reste',
        artists: 'par Clara Luciani',
        label: 'Sony Music Entertainment',
        passages: 37480,
        duration: 3400,
        revenueEstimated: 10000,
      },
      {
        id: 4,
        growth: 10,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
      {
        id: 5,
        growth: 7,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
      {
        id: 2,
        growth: -3,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
      {
        id: 3,
        growth: 0,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
      {
        id: 1,
        growth: 10,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
      {
        id: 2,
        growth: -3,
        title: 'Fbleu 2021 Jui 8',
        artists: 'par 21 Juin',
        label: 'Signature',
        passages: 14880,
        duration: 14000,
        revenueEstimated: 14000,
      },
    ]
  },
  mediaData: {
    columns: ['media', 'noOfPassage', 'broadcastDuration', 'revenueEstimated'],
    rows: [
      {
        media: 'TV',
        noOfPassage: 16070,
        broadcastDuration: '463h 01m 14s',
        revenueEstimated: 16000,
      },
      {
        media: 'Radio',
        noOfPassage: '5394',
        broadcastDuration: '249h 45m 00s',
        revenueEstimated: 9000,
      },
    ]
  },
  revenueEstimate:
    [{
      name: 'TV',
      data: [64, 55, 71, 72, 73, 53, 61, 75, 71, 72, 81, 85],
    }, {
      name: 'Radio',
      data: [14, 25, 16, 17, 12, 13, 15, 10, 9, 18, 13, 15]
    }
    ]
};
