import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthModule } from './auth/auth.module';
import { GlobalErrorHandler } from './error/global-error-handler';
import { IconsModule } from './icons/icons.module';
import { LayoutModule } from './layout';

@NgModule({
  imports: [
    AuthModule,
    IconsModule,
    LayoutModule,
    MatTooltipModule,
    MatSnackBarModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Import this module in the AppModule only.'
      );
    }
  }
}
