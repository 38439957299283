export interface User
{
    // id: string;
    // name: string;
    username:  string,
    full_name:  string
    email: string;
    avatar?: string;
    status?: string;
}
