/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { getCurrencySymbol } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'apps/bmat-starter/src/environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';

const BASE_URL = environment.API_URL;

@Injectable()
export class SharedService {
  private _data: BehaviorSubject<any> = new BehaviorSubject(null);
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for data
   */
  get data$(): Observable<any> {
    return this._data.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get data
   */
  getData(): Observable<any> {
    return this._httpClient.get('api/dashboards/landing').pipe(
      tap((response: any) => {
        this._data.next(response);
      })
    );
  }

  /**
   * Get Royalties data ..
   */
  getRoyaltiesRanking(allParams: any = {}): Observable<any> {
    if (!allParams.year) allParams.year = new Date().getFullYear();
    // TODO : move it at some common place
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (value) parsedParams = parsedParams.append(key, value);
    });
    return this._httpClient.get(`${BASE_URL}royalties/rankings/tracks`, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }
  /**
   * Get Royalties data ..
   */
  getRoyaltiesRankingArtist(allParams: any = {}): Observable<any> {
    if (!allParams.year) allParams.year = new Date().getFullYear();
    // TODO : move it at some common place
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (value) parsedParams = parsedParams.append(key, value);
    });
    return this._httpClient.get(`${BASE_URL}royalties/rankings/artists`, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  /* 
  Download top 10
  */
  downloadArtists(allParams: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        'Access-Control-Expose-Headers': '*',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });

    const urlString = `${BASE_URL}royalties/rankings/artists/download`;
    return this._httpClient.get(urlString, {
      headers: httpOptions.headers,
      params: parsedParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  /* 
  Download top 10
  */
  downloadTracks(allParams: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        'Access-Control-Expose-Headers': '*',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });

    const urlString = `${BASE_URL}royalties/rankings/tracks/download`;
    return this._httpClient.get(urlString, {
      headers: httpOptions.headers,
      params: parsedParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Get Royalties data ..
   */
  getRoyaltiesRankingArtistFilter(): Observable<any> {
    return this._httpClient.get(`${BASE_URL}royalties/filter`);
  }
  /**
   * Get Royalties data ..
   */
  getMonthlyRevenue(allParams: any = {}): Observable<any> {
    if (!allParams.years) allParams.years = new Date().getFullYear();
    // TODO : move it at some common place
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (value) parsedParams = parsedParams.append(key, value);
    });
    const urlString = `${BASE_URL}royalties/totals`;
    return this._httpClient.get(urlString, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  // getMedia(): Observable<any> {
  //   return this._httpClient.get(`${BASE_URL}royalties/filter/media`).pipe(
  //     tap((response: any) => {
  //       this._data.next(response);
  //     })
  //   );
  // }

  /**
   * Get Royalties data ..
   */
  getTotal(allParams: any = {}): Observable<any> {
    if (!allParams.years) allParams.years = new Date().getFullYear();
    // TODO : move it at some common place

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });
    const urlString = `${BASE_URL}royalties/totals`;
    return this._httpClient.get(urlString, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  /**
   * Get Royalties data ..
   */
  getAverage(allParams: any = {}): Observable<any> {
    // TODO : move it at some common place
    if (!allParams.years) allParams.years = new Date().getFullYear();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });

    const urlString = `${BASE_URL}royalties/average`;
    return this._httpClient.get(urlString, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  /**
   * Get Royalties data ..
   */
  getRoyalties(allParams: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });

    const urlString = `${BASE_URL}royalties/filter`;
    return this._httpClient.get(urlString, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  getLabels(allParams: any = {}): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });
    return this._httpClient
      .get(`${BASE_URL}royalties/filter/labels`, {
        headers: httpOptions.headers,
        params: parsedParams,
      })
      .pipe(
        tap((response: any) => {
          this._data.next(response);
        })
      );
  }

  /**
   * Get Royalties data ..
   */
  downloadSearch(allParams: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        //'Content-Type': 'application/json',
        'Access-Control-Expose-Headers': '*',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });

    const urlString = `${BASE_URL}royalties/filter/download`;
    return this._httpClient.get(urlString, {
      headers: httpOptions.headers,
      params: parsedParams,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getChannels(allParams: any = {}): Observable<any> {
    // TODO : move it at some common place
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });
    const urlString = `${BASE_URL}royalties/filter/channels`;
    return this._httpClient
      .get(urlString, { headers: httpOptions.headers, params: parsedParams })
      .pipe(
        tap((response: any) => {
          this._data.next(response);
        })
      );
  }

  getChannelGroup(allParams: any = {}): Observable<any> {
    // TODO : move it at some common place
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });
    const urlString = `${BASE_URL}royalties/filter/channel_groups`;
    return this._httpClient
      .get(urlString, { headers: httpOptions.headers, params: parsedParams })
      .pipe(
        tap((response: any) => {
          this._data.next(response);
        })
      );
  }

  getMedia(): Observable<any> {
    return this._httpClient.get(`${BASE_URL}royalties/filter/media`);
    // .pipe(
    //   tap((response: any) => {
    //     this._data.next(response);
    //   })
    // );
  }

  getArtists(allParams: any = {}): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });
    return this._httpClient.get(`${BASE_URL}royalties/filter/artists`, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  getIsrc(allParams: any = {}): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });
    return this._httpClient.get(`${BASE_URL}royalties/filter/isrcs`, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  getTracks(allParams: any = {}): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let parsedParams = new HttpParams();
    Object.entries(allParams).forEach((entry: any) => {
      const [key, value] = entry;
      if (Array.isArray(value)) {
        // console.log(" here is the array value ",key, value)
        for (let i = 0; i < value.length; i++) {
          parsedParams = parsedParams.append(key, value[i]);
        }
        // console.log(" aftr  here is the array value ",parsedParams);
      } else if (value) {
        parsedParams = parsedParams.append(key, value);
      }
    });
    return this._httpClient.get(`${BASE_URL}royalties/filter/tracks`, {
      headers: httpOptions.headers,
      params: parsedParams,
    });
  }

  // TODO move to utility class
  numberScaleFormat(value: any) {
    value =
      Math.abs(Number(value)) >= 1.0e9
        ? (Math.abs(Number(value)) / 1.0e9).toFixed(0) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? (Math.abs(Number(value)) / 1.0e6).toFixed(0) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? (Math.abs(Number(value)) / 1.0e3).toFixed(0) + 'K'
        : Math.abs(Number(value));
    return value;
  }

  currencyScaleFormat(value: any) {
    value =
      Math.abs(Number(value)) >= 1.0e9
        ? (Math.abs(Number(value)) / 1.0e9).toFixed(0) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(value)) >= 1.0e6
        ? (Math.abs(Number(value)) / 1.0e6).toFixed(0) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(value)) >= 1.0e3
        ? (Math.abs(Number(value)) / 1.0e3).toFixed(0) + 'K'
        : Math.abs(Number(value));
    return value + getCurrencySymbol('EUR', 'narrow');
  }
}
