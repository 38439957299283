/* eslint-disable */

import { NavigationItem } from '@bmat-starter/ui';

export const defaultNavigation: NavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Mes droits estimés',
    subtitle: '',
    type: 'group',
    icon: 'heroicons_outline:home',
    link: '/advanced-search',
  },
  {
    id: 'dashboards.Search',
    title: 'Faire une recherche',
    type: 'basic',
    icon: 'heroicons_outline:search',
    link: '/advanced-search',
  },
  {
    id: 'dashboards.project',
    title: 'Mon Top 10',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-check',
    link: '/landing',
    target: 'top10ranks',
  },
];
export const compactNavigation: NavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Mes droits estimés',
    tooltip: 'Mes droits estimés',
    type: 'aside',
    icon: 'heroicons_outline:home',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },

  //   {
  //     id      : 'user-interface',
  //     title   : 'UI',
  //     tooltip : 'UI',
  //     type    : 'aside',
  //     icon    : 'heroicons_outline:collection',
  //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  // },
];
export const futuristicNavigation: NavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Mes droits estimés',
    type: 'group',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },

  {
    id: 'user-interface',
    title: 'User Interface',
    type: 'aside',
    icon: 'heroicons_outline:collection',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
export const horizontalNavigation: NavigationItem[] = [
  {
    id: 'dashboards',
    title: 'Mes droits estimés',
    subtitle: '',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/landing',
  },
  {
    id: 'dashboards.Search',
    title: 'Faire une recherche',
    type: 'basic',
    icon: 'heroicons_outline:search',
    link: '/advanced-search',
  },
  {
    id: 'dashboards.project',
    title: 'Mon Top 10',
    type: 'basic',
    icon: 'heroicons_outline:clipboard-check',
    link: '/top-10',
    // target:'top10ranks'
  },
];
