import { NgModule } from '@angular/core';
import { ScrollResetDirective } from './scroll-reset.directive';

@NgModule({
    declarations: [
       ScrollResetDirective
    ],
    exports     : [
        ScrollResetDirective
    ]
})
export class ScrollResetModule
{
}
