<a
  [matMenuTriggerFor]="membersActions"
  #trigger="matMenuTrigger"
  (mouseenter)="trigger.openMenu()"
  class="p-2"
>
  <!-- <span>{{selectedMember!='all'?selectedMember.name:'Select Member'}}</span> -->
  <mat-icon
    class="icon-size-5 text-white mt-1"
    [svgIcon]="'heroicons_outline:office-building'"
  ></mat-icon>
</a>

<mat-menu [xPosition]="'before'" #membersActions="matMenu" class="-m-8">
  <span (mouseleave)="closeMenu($event)">
    <button
      *ngIf="!singleLabel"
      [ngClass]="{ 'bg-gray-300': selectedMember == 'all' }"
      mat-menu-item
      (click)="onSelection('all')"
    >
      <span class="flex flex-col leading-none">
        <span> {{ 'ui.allLabels' | transloco }} </span>
      </span>
    </button>
    <button
      [ngClass]="{
        'bg-gray-300': selectedMember && selectedMember.name == option.name
      }"
      mat-menu-item
      *ngFor="let option of membersList | async"
      (click)="onSelection(option)"
    >
      <span class="flex flex-col leading-none">
        <span>{{ option.name }}</span>
      </span>
    </button>
  </span>
</mat-menu>
