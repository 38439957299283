<!-- Loading bar -->
<ui-loading-bar></ui-loading-bar>

<div
  class="flex flex-auto justify-center w-full sm:p-4 md:p-8 bg-gray-200 dark:bg-card"
>
  <!-- Navigation -->
  <ng-container *ngIf="isScreenSmall">
    <vertical-navigation
      *ngIf="navigation"
      class="dark bg-gray-900 print:hidden"
      [mode]="'over'"
      [name]="'mainNavigation'"
      [navigation]="navigation.default"
      [opened]="false"
    >
      <!-- Navigation header hook -->
      <ng-container uiVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 pt-6 px-8">
          <img class="w-30" src="assets/logo/logo-scpp-white.png" />
        </div>
      </ng-container>
    </vertical-navigation>
  </ng-container>

  <!-- Wrapper -->
  <div
    class="flex flex-col items-center flex-auto min-w-0 max-w-360 sm:rounded-xl shadow-2xl dark:shadow-none overflow-hidden"
  >
    <!-- Header -->
    <div
      class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 bg-card border-b dark:bg-default print:hidden"
    >
      <ng-container *ngIf="!isScreenSmall">
        <!-- Logo -->
        <div class="flex items-center mx-2 lg:mr-8">
          <div class="hidden lg:flex">
            <!-- Light version -->
            <img
              class="dark:hidden w-24"
              src="assets/logo/logo-scpp.png"
              alt="Logo image"
            />
            <!-- Dark version -->
            <img
              class="hidden dark:flex w-24"
              src="assets/logo/logo-scpp-white.png"
              alt="Logo image"
            />
          </div>
          <!-- Small version -->
          <img
            class="flex lg:hidden w-8"
            src="assets/logo/logo-scpp.png"
            alt="Logo image"
          />
        </div>
        <!-- Horizontal navigation -->
        <horizontal-navigation
          class="mr-2"
          [name]="'mainNavigation'"
          [navigation]="navigation.horizontal"
        ></horizontal-navigation>
      </ng-container>
      <!-- Navigation toggle button -->
      <ng-container *ngIf="isScreenSmall">
        <button
          class="mr-2"
          mat-icon-button
          (click)="toggleNavigation('mainNavigation')"
        >
          <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
      </ng-container>
      <!-- Components -->
      <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
        <fullscreen class="hidden md:block"></fullscreen>
        <messages></messages>
        <notifications></notifications>
        <user></user>
      </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full bg-default">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div
      class="relative flex flex-0 items-center justify-start w-full h-16 sm:h-20 px-6 sm:px-8 z-49 bg-card border-t dark:bg-default print:hidden"
    >
      <span class="font-medium text-secondary"
        >Nous utilisons la donnée de durée totale d’utilisation de chaque titre
        identifiée sur la période observée grâce aux technologies d’empreinte,
        nous les rapprochons des informations membres, et les multiplions par
        les estimations de la valeur de la seconde de diffusion provenant de la
        SCPP.</span
      >
    </div>
  </div>
</div>
