import { Component } from '@angular/core';

@Component({
  selector: 'mutating-dots',
  templateUrl: './mutating-dots.component.html',
})


export class MutatingDotsComponent {
  height = 100;
  width = 150;
  radius = 12.5;
  color = '#00478D';
  secondaryColor = '#00478D';
  ariaLabel = 'mutating-dots-loading';
  wrapperStyle = 'tt';
  wrapperClass = 'tt2';
  visible = true;

}
