import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgModule,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import * as d3 from 'd3';
import { BehaviorSubject, of } from 'rxjs';

@Component({
  selector: 'sunburst',
  templateUrl: './sunburst.component.html',
  styleUrls: ['./sunburst.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SunburstComponent implements AfterViewInit {
  @ViewChild('chartContainer') chartContainer!: ElementRef<HTMLElement>;
  @Input() chartData!: BehaviorSubject<any>;
  @Input() arcVisible!: number;
  @Input() fontSize = '10px sans-serif';

  currentLanguage = 'fr';
  constructor(private translate: TranslocoService) {}

  setupD3(container: HTMLElement, data: any) {
    const chart = (data: any) => {
      console.log(
        'container .................. ',
        container.offsetWidth,
        container.offsetHeight
      );
      const hierarchy: any = d3
        .hierarchy(data)
        .sum((d: any) => d.value)
        .sort((a: any, b: any) => b.value - a.value);

      const partition = d3
        .partition()
        .size([2 * Math.PI, hierarchy.height + 1]);

      const root = partition(hierarchy);

      const format = (d: any) => d3.format(',d');

      const width = 928;
      const height = width;
      const radius =
        hierarchy.height == 1
          ? width / 5
          : hierarchy.height == 2
          ? width / 7
          : width / 10;

      const color = d3.scaleOrdinal(
        d3.quantize(
          d3.interpolateHcl('#00478D', '#7DD2F0'),
          data.children.length + 1
        )
      );

      const labelVisible = (d: any) => {
        return (
          d.y1 <= this.arcVisible &&
          d.y0 >= 1 &&
          (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03
        );
      };

      const labelTransform = (d: any) => {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = ((d.y0 + d.y1) / 2) * radius;
        return `rotate(${x - 90}) translate(${y},0) rotate(${
          x < 180 ? 0 : 180
        })`;
      };

      root.each((d: any) => (d.current = d));

      // Create the arc generator.
      const arc = d3
        .arc()
        .startAngle((d: any) => {
          return d.x0;
        })
        .endAngle((d: any) => d.x1)
        .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius((d: any) => d.y0 * radius)
        .outerRadius((d: any) => Math.max(d.y0 * radius, d.y1 * radius - 1));

      // Create the SVG container.
      const svg = d3
        .create('svg')
        .attr('viewBox', [-width / 2, -height / 2, width, width])
        .style('font', this.fontSize);

      const g = svg
        .append('g')
        //.attr("transform", `translate(${container.offsetWidth / 4},${container.offsetWidth / 4})`)
        //.attr("transform", `translate(0,0)`)
        .on('mouseleave', mouseleave);

      const label = g
        .append('g')
        .attr('pointer-events', 'none')
        .attr('text-anchor', 'middle')
        .style('user-select', 'none')
        .selectAll('text')
        .data(root.descendants().slice(1))
        .enter()
        .append('text')
        .attr('dy', '0.35em')
        .attr('fill-opacity', (d: any) => +labelVisible(d.current))
        .attr('transform', (d: any) => labelTransform(d.current))
        .each(function (d: any) {
          const words = d.data.name.split(' '); // Split name into words
          const maxCharsPerLine = 17; // Adjust as needed for better fit
          const lines = [];
          let line = words[0];

          for (let i = 1; i < words.length; i++) {
            if ((line + ' ' + words[i]).length > maxCharsPerLine) {
              lines.push(line);
              line = words[i];
            } else {
              line += ' ' + words[i];
            }
          }
          lines.push(line);

          // Append each line as a <tspan>
          lines.forEach((text, i) => {
            d3.select(this)
              .append('tspan')
              .attr('x', 0)
              .attr('dy', i === 0 ? '0em' : '1.2em') // Adjust line spacing
              .text(text);
          });
        });

      //percentage text
      const percentage_text = svg
        .append('text')
        .attr('id', 'title')
        .attr('x', 0)
        .attr('y', 0)
        .attr('text-anchor', 'middle')
        .style('font-size', '2.5em');

      const arcVisible = (d: any) => {
        // console.log(' arch visible is ', this.arcVisible);
        return d.y1 <= this.arcVisible && d.y0 >= 1 && d.x1 > d.x0;
      };

      //mouse over
      const totalSize = root.descendants()[0].value as number;
      const mouseover = (event: any, d: any) => {
        const percentage = d?.value
          ? parseFloat(((100 * d.value) / totalSize).toFixed(1))
          : 0; // avoiding d.value not found
        let percentageString = percentage + '%';
        if (percentage < 0.1) {
          percentageString = '< 0.1%';
        }
        percentage_text.text(percentageString);

        ///  console.log("d is ", d);

        const sequenceArray = d ? d.ancestors().reverse() : [];
        if (sequenceArray.length > 0) {
          sequenceArray.shift(); // remove root node from the array
          // Fade all the segments.
          d3.selectAll('path').style('opacity', 0.3);

          // Then highlight only those that are an ancestor of the current segment.
          g.selectAll('path')
            .filter(function (node) {
              return sequenceArray.indexOf(node) >= 0;
            })
            .style('opacity', 1);
        }
      };
      //mouse leave
      // Restore everything to full opacity when moving off the visualization.
      function mouseleave(d: any) {
        // Deactivate all segments during transition.
        //d3.selectAll("path").on("mouseover", null);

        // Transition each segment to full opacity and then reactivate it.
        d3.selectAll('path').transition().duration(200).style('opacity', 1);

        percentage_text.text('');
      }

      const parent = g
        .append('circle')
        .datum(root)
        .attr('r', radius)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on('click', clicked);

      function clicked(p: any) {
        parent.datum(p.parent || root);

        root.each(
          (d: any) =>
            (d.target = {
              x0:
                Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              x1:
                Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              y0: Math.max(0, d.y0 - p.depth),
              y1: Math.max(0, d.y1 - p.depth),
            })
        );
        const t = g.transition().duration(750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path
          .transition()
          .tween('data', (d: any) => {
            const i = d3.interpolate(d.current, d.target);
            return (t: any) => (d.current = i(t));
          })
          .filter((d: any) => {
            return arcVisible(d.target); // this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
          .attr('fill-opacity', (d: any) =>
            arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
          );
        //.attrTween("d", (d:any) => () => arc(d.current));

        label
          .filter((d: any) => {
            return labelVisible(d.target); // d.getAttribute("fill-opacity") || labelVisible(d.target);
          })
          .transition()
          .attr('fill-opacity', (d: any) => +labelVisible(d.target))
          .attrTween('transform', (d: any) => () => labelTransform(d.current));
      }

      const path = g
        .append('g')
        .selectAll('path')
        .data(root.descendants().slice(1))
        .enter()
        .append('path')
        .attr('fill', (d: any) => {
          while (d.depth > 1) d = d.parent;
          return color(d.data.name);
        })
        .attr('fill-opacity', (d: any) =>
          arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr('d', (d: any) => arc(d.current))
        .on('mouseover', mouseover);

      path
        .filter((d: any) => d.children)
        .style('cursor', 'pointer')
        .on('click', clicked);

      path.append('title').text((d: any) => {
        // console.log(" ...",d.ancestors().map((d:any) => d.data.name))
        const tree = d.ancestors().map((d: any) => d.data.name);
        if (tree.length > 1) {
          tree.pop();
        }
        return `${tree.reverse().join(' / ')}\n${parseFloat(d.value).toFixed(
          d.value < 100 ? 2 : 0
        )} €`;
      }); //${d.value}`});

      container.append(svg.node()!);
    };
    ///chart(this.);

    chart(data);
  }

  ngAfterViewInit(): void {
    this.chartData.subscribe({
      next: (data) => {
        this.chartContainer.nativeElement.innerHTML = '';
        // console.log('------data comming ', data);
        const translatedData = this.changeNames(data);
        this.setupD3(this.chartContainer.nativeElement, translatedData);
        //  console.log(" setup executed successfully ");
      },
    });
    this.currentLanguage = this.translate.getActiveLang();
    this.translate.langChanges$.subscribe((lang) => {
      this.currentLanguage = lang;
    });
  }

  changeNames(data: any): any {
    return {
      name: data.name,
      children: data.children.map((item: any) => {
        let translatedName = item.name;
        if (
          item.name === 'tv' ||
          item.name === 'Télévisions' ||
          item.name === 'TV'
        ) {
          translatedName = this.currentLanguage === 'fr' ? 'Télévisions' : 'TV';
        } else if (item.name === 'radio') {
          translatedName = 'Radios';
        }
        return {
          ...item,
          name: translatedName,
          children: item.children,
        };
      }),
    };
  }
}

@NgModule({
  declarations: [SunburstComponent],
  exports: [SunburstComponent],
})
export class SunburstModule {}
