import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@Pipe({
  name: 'hoursMinSec',
})
export class HoursMinSecPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): unknown {

    if (value) {
      const intervals: { [key: string]: number } = {
        // 'year': 31536000,
        //'month': 2592000,
        // 'week': 604800,
        //'day': 86400,
        'h': 3600,
        'm': 60,
        's': 1
      };

      const counter = [];
      for (const i in intervals) {
        const part = Math.floor(value / intervals[i]);
        if (part > 0){
          if (part === 1) {
            counter.push(' ' + part + '' + i + ''); // singular (1 day ago)
            value = value - intervals[i];
          } else {
            counter.push(' ' + part + '' + i); // singular (1 day ago) return counter + ' ' + i + 's'; // plural (2 days ago)
            value = value - (part * intervals[i]);
          }
        }else if(part ==0) {
          counter.push(' ' + part + '' + i);
        }
        if (value <= 0) {
          break;
        }
      }

      return counter.join(" ");
    }
    return value;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [HoursMinSecPipe],
  exports: [HoursMinSecPipe],
})
export class HoursMinSecPipeModule { }
