import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  UINavigationService,
  VerticalNavigationComponent,
} from '@bmat-starter/ui';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs';
// import { FuseNavigationService, UIVerticalNavigationComponent } from '@fuse/components/navigation';

@Component({
  selector: 'languages',
  templateUrl: './languages.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
  availableLangs!: any; // AvailableLangs;
  activeLang!: string;
  flagCodes: any;

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _uiNavigationService: UINavigationService,
    private _translocoService: TranslocoService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the available languages from transloco
    this.availableLangs = this._translocoService.getAvailableLangs();

    // Subscribe to language changes
    this._translocoService.langChanges$.subscribe({
      next: (activeLang: any) => {
        // Get the active lang
        this.activeLang = activeLang;

        // Update the navigation
        this._updateNavigation(activeLang);
      },
    });

    // Set the country iso codes for languages for flags
    this.flagCodes = {
      en: 'uk',
      fr: 'fr',
    };
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the active lang
   *
   * @param lang
   */
  setActiveLang(lang: string): void {
    // Set the active lang
    this._translocoService.setActiveLang(lang);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the navigation
   *
   * @param lang
   * @private
   */
  private _updateNavigation(lang: string): void {
    // For the demonstration purposes, we will only update the Dashboard names
    // from the navigation but you can do a full swap and change the entire
    // navigation data.
    //
    // You can import the data from a file or request it from your backend,
    // it's up to you.

    // Get the component -> navigation data -> item
    const navComponent =
      this._uiNavigationService.getComponent<VerticalNavigationComponent>(
        'mainNavigation'
      );

    // Return if the navigation component does not exist
    if (!navComponent) {
      return;
    }

    // Get the flat navigation data
    const navigation = navComponent.navigation;

    // Get the Project dashboard item and update its title
    const projectDashboardItem1 = this._uiNavigationService.getItem(
      'dashboards',
      navigation
    );
    if (projectDashboardItem1) {
      this._translocoService
        .selectTranslate('estimates')
        .pipe(take(1))
        .subscribe({
          next: (translation: any) => {
            // Set the title
            projectDashboardItem1.title = translation;

            // Refresh the navigation component
            navComponent.refresh();
          },
        });
    }
    const projectDashboardItem2 = this._uiNavigationService.getItem(
      'dashboards.Search',
      navigation
    );
    if (projectDashboardItem2) {
      this._translocoService
        .selectTranslate('search')
        .pipe(take(1))
        .subscribe({
          next: (translation: any) => {
            // Set the title
            projectDashboardItem2.title = translation;

            // Refresh the navigation component
            navComponent.refresh();
          },
        });
    }
    const projectDashboardItem = this._uiNavigationService.getItem(
      'dashboards.project',
      navigation
    );
    if (projectDashboardItem) {
      this._translocoService
        .selectTranslate('top10')
        .pipe(take(1))
        .subscribe({
          next: (translation: any) => {
            // Set the title
            projectDashboardItem.title = translation;

            // Refresh the navigation component
            navComponent.refresh();
          },
        });
    }

    // Get the Analytics dashboard item and update its title
    const analyticsDashboardItem = this._uiNavigationService.getItem(
      'dashboards.analytics',
      navigation
    );
    if (analyticsDashboardItem) {
      this._translocoService
        .selectTranslate('Analytics')
        .pipe(take(1))
        .subscribe({
          next: (translation: any) => {
            // Set the title
            analyticsDashboardItem.title = translation;

            // Refresh the navigation component
            navComponent.refresh();
          },
        });
    }
  }
}
